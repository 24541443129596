<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button style="margin-bottom:20px" type="primary" icon="el-icon-plus" size="small" @click="$router.push({ name: 'JobAdd' })">添加</el-button>
    <tp-table
      :isNeedSerialNumber="false"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
  </div>
</template>

<script>
const columns = [
  {
    label: '岗位名称',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '岗位编码',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '描述',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '创建时间',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  
  {
    label: '操作',
    width: '200',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link underline={false} type="primary" onClick={() => this.$router.push({ name: 'JobEdit', params: { id: row['id'] } })}>
            编辑
          </el-link>
          <el-link style="margin-left:10px;" underline={false} type="primary" onClick={() => this.handleDel(row)}>
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',

  data() {
    return {
      dialogVisible: false,
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [{ id: 111 }],
      formPwd: {}
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},

  methods: {
    // 删除
    handleDel() {
      this.$confirm('此操作将永久删除该岗位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 启用/禁用
    changeStatus(row) {}
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
